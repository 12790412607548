import DatePickerIcon from '@mui/icons-material/CalendarToday';
import AddUsersIcon from '@mui/icons-material/GroupAdd';
import KeyBoardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyBoardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconWarning from '@mui/icons-material/Warning';
import { CircularProgress, FormControl, IconButton, NativeSelect, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { ADVANCED, STANDARD } from 'components/constants/machine-template';
import Loader from 'components/loader/loader';
import { MANAGER, USER } from 'constants/user-authority';
import { expiresInNbMonths, formatDateFromMillis, formatDateTimeFromMillis, isExpiredDate } from 'services/date-service';
import resourcePathService from 'services/resource-path-service';
import userService from 'services/user-service';
import vmInstanceService from 'services/vm-instance-service';
import { useAppDispatch } from 'store';
import { addMachineTemplates } from 'store/actions/machine-template-actions';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { AccessMgtDataDTO, AvailableMachineTemplateDTO, FilterAclObject, ResourceAccessNode, SortObject, UpdateAccessDTO } from 'types';
import AddUsers from './add-users';
import styles from './manage-users.module.scss';
import UserDeleteButton from './user-delete-button';

const COL_ACLID = 0;
const COL_USERID = 1;
const COL_SITEID = 2;
const COL_ZONEID = 3;
const COL_PROJECTID = 4;
// const COL_FIRSTNAME = 5;
// const COL_LASTNAME = 6;
const COL_HUMANREADABLEPATH = 7;
const COL_SITE = 8;
const COL_ZONE = 9;
const COL_PROJECT = 10;
const COL_AUTHORITY = 11;
const COL_EXPIRATIONDATE = 12;
const COL_ISEXPIRED = 13;
const COL_EXPIRESOON = 14;
const COL_CATEGORY = 15;
//const COL_LASTMODIFYDATE = 16;
const COL_LASTMODIFYBY = 17;

const RESOURCETYPE_SITE = 'SITE';
const RESOURCETYPE_ZONE = 'ZONE';
const RESOURCETYPE_PROJECT = 'PROJECT';

const mapState = (state: RootState) => ({
  siteList: state.resourcesReducer.siteList,
  loggedUserId: state.loggedUserReducer.id,
  getSiteAvailableMachineTemplatesFromState: (siteId: number) => getSiteAvailableMachineTemplatesFromState(state, siteId),
  isManager: state.resourcesReducer.siteList.filter(site => site.authority === MANAGER
    || site.children.some(zone => zone.authority === MANAGER
      || zone.children.some(project => project.authority === MANAGER))).length > 0,
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps;

type Row = {
  aclId: number,
  expirationDate: string,
  lastModifiedBy: string,
  lastModifiedDate: number,
  machineTemplateId: number,
  siteId: number,
  resourcePath: string,
  permissionAuthority: string,
  userFirstname: string,
  userId: string,
  userLastname: string,
};

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);
  return debouncedValue;
};

const ManageUsers = ({ siteList, loggedUserId, isManager, getSiteAvailableMachineTemplatesFromState }: Props) => {
  const dispatch = useAppDispatch();
  const [localData, setLocalData] = useState<Row[]>([]);
  const [isReady, setReady] = React.useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [tableState, setTableState] = useState<{ rowsPerPage: number, sortOrder: SortObject, filterList: string[], searchText: string, page: number }>({
    page: 0,
    rowsPerPage: 25,
    sortOrder: {
      name: 'createdDate', //default sort order name must exist in backend
      direction: 'desc',
    },
    filterList: [],
    searchText: '',
  });
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebouncedValue(searchText, 300);
  const [pendingUpdates, setPendingUpdates] = useState<number[]>([]);

  const getResourceType = row => {
    return row[COL_PROJECTID] ? RESOURCETYPE_PROJECT : row[COL_ZONEID] ? RESOURCETYPE_ZONE : RESOURCETYPE_SITE;
  };

  const isConnectedUserManager = (rowData: Row) => {
    const resource = resourcePathService.getResource({sites: siteList}, rowData[COL_HUMANREADABLEPATH]);

    return resource ? resource.authority === MANAGER : false;
  };

  const isCurrentUser = rowData => {
    return loggedUserId === rowData[COL_USERID];
  };

  const transform = (rights: AccessMgtDataDTO): Row[] => {
    if (rights === null || rights.accessRows === null) {
      return [];
    }
    //The names here are used in the backend for sorting so they should not be changed
    return rights.accessRows.map(right => ({
      aclId: right.accessControlId,
      userId: right.userId,
      userFirstname: right.firstName,
      userLastname: right.lastName,
      siteId: right.machineTemplate.siteId,
      resourcePath: right.humanReadablePath,
      permissionAuthority: right.authority,
      expirationDate: right.expirationDate.toString(),
      machineTemplateId: right.machineTemplate.id,
      lastModifiedDate: right.lastModifiedDate,
      lastModifiedBy: right.lastModifiedBy,
      isExpired: '', //Only for filter
      willExpireWithin30Days: '', //Only for filter
    }));
  };

  const updateState = (rows: AccessMgtDataDTO) => {
    setCount(rows.totalElements);
    setPage(rows.currentPage);
  };

  const endOfReload = () => {
    setReady(true);
  };

  const changePage = (newPage: number, rowsPerPage: number, sortOrder: SortObject, searchText: string, filterList) => {
    setPendingUpdates([]);
    const filterAclObject: FilterAclObject = {
      siteLabel: typeof filterList[COL_SITE] !== 'undefined' ? filterList[COL_SITE][0] : '',
      zoneName: typeof filterList[COL_ZONE] !== 'undefined' ? filterList[COL_ZONE][0] : '',
      projectName: typeof filterList[COL_PROJECT] !== 'undefined' ? filterList[COL_PROJECT][0] : '',
      permissionAuthority: typeof filterList[COL_AUTHORITY] !== 'undefined' ? filterList[COL_AUTHORITY][0] : '',
      expired: typeof filterList[COL_ISEXPIRED] !== 'undefined' && filterList[COL_ISEXPIRED].length > 0,
      expireSoon: typeof filterList[COL_EXPIRESOON] !== 'undefined' && filterList[COL_EXPIRESOON].length > 0,
    };
    userService.getUserListToManage(newPage, rowsPerPage, searchText, sortOrder, filterAclObject).then(users => {
      updateState(users);
      setLocalData(transform(users));
      setPendingUpdates([]);
    })
      .finally(endOfReload);
  };

  const forceRefresh = () => {
    changePage(tableState.page, tableState.rowsPerPage, tableState.sortOrder, tableState.searchText, tableState.filterList);
  };

  const refresh = () => {
    forceRefresh();
  };

  useEffect(() => {
    (async () => {
      if (!isReady) {
        forceRefresh();
      }
    })();
  }, []);

  useEffect(() => {
    new Set(localData.map(row => row.siteId)).forEach(siteId => {
      const availableMachineTemplates = getSiteAvailableMachineTemplatesFromState(siteId);
      if (!availableMachineTemplates) {
        vmInstanceService.getMachineTemplates(siteId).then((machineTemplates) => {
          const result = { [siteId]: machineTemplates };
          dispatch(addMachineTemplates(result));
        });
      }
    });
  }, [localData]);

  useEffect(() => {
    changePage(tableState.page, tableState.rowsPerPage, tableState.sortOrder, searchText, tableState.filterList);
  }, [debouncedSearchTerm]);

  const isTemplateValid = (machineTemplateId: number, templates) => {
    return templates && templates.map(t => t.id == machineTemplateId);
  };

  const canEditStatus = rowData => {
    return isConnectedUserManager(rowData) && !isCurrentUser(rowData);
  };

  const canEditDefaultMachineTemplate = rowData => {
    return isConnectedUserManager(rowData);
  };

  const canEditExpirationDate = (rowData): boolean => {
    //Manager can edit its own expiration date
    return isConnectedUserManager(rowData);
  };

  const canDeleteAcl = rowData => {
    // User cannot delete its own access, otherwise OK
    return isConnectedUserManager(rowData) && !isCurrentUser(rowData);
  };

  const getUpdateBody = (rowData: Row): UpdateAccessDTO => {
    return {
      siteId: rowData[COL_SITEID],
      zoneId: rowData[COL_ZONEID] ? rowData[COL_ZONEID] : null,
      projectId: rowData[COL_PROJECTID] ? rowData[COL_PROJECTID] : null,
      authority: rowData[COL_AUTHORITY],
      machineTemplateId: rowData[COL_CATEGORY],
      expirationDate: DateTime.fromISO(rowData[COL_EXPIRATIONDATE]).toFormat('dd/LL/yyyy'),
      resourceType: getResourceType(rowData),
    };
  };

  const updateUser = (rowData: Row) => {
    setPendingUpdates(pendingUpdates.concat(rowData[COL_ACLID]));
    const body = getUpdateBody(rowData);
    return userService.updateUserAccessControl(rowData[COL_ACLID], body).then(refresh).catch(forceRefresh).finally(endOfReload);
  };

  const handleDateChange = (data, rowData, date) => {
    // eslint-disable-next-line no-param-reassign
    rowData[COL_EXPIRATIONDATE] = date;
    localData[data.currentTableData[data.rowIndex].index].expirationDate = date;
    updateUser(rowData);
  };

  const handlePermissionChange = (data, rowData, newPermission) => {
    // eslint-disable-next-line no-param-reassign
    rowData[COL_AUTHORITY] = newPermission;
    localData[data.currentTableData[data.rowIndex].index].permissionAuthority = newPermission;
    updateUser(rowData);
  };

  const handleMachineTemplateChange = (data, rowData, newTemplate) => {
    // eslint-disable-next-line no-param-reassign
    rowData[COL_CATEGORY] = newTemplate;
    localData[data.currentTableData[data.rowIndex].index].machineTemplateId = newTemplate;
    updateUser(rowData);
  };

  const getMachineTemplateOptionColor = (machineTemplateId: number, templates: AvailableMachineTemplateDTO[]) => {
    const template = templates?.find(t => t.id == machineTemplateId);
    if (!template) return styles.manage_user__darkRedOption;
    switch (template.name) {
      case ADVANCED:
        return styles.manage_user__lightBlueOption;
      case STANDARD:
        return styles.manage_user__darkSkyBlueOption;
      default:
        return styles.manage_user__darkBlueOption;
    }
  };

  //Used for filters
  function flatten (projects: ResourceAccessNode[]): ResourceAccessNode[] {
    const allProjects: ResourceAccessNode[] = projects;
    let currentProjects = projects.flatMap(p => p.children);
    while (currentProjects.length > 0) {
      allProjects.concat(currentProjects);
      currentProjects = currentProjects.flatMap(p => p.children);
    }
    return allProjects;
  }

  const allSitesLabels = siteList.map(s => s.label);
  const allZonesNames = siteList.flatMap(s => s.children).map(z => z.name);//Display all zone names, even those with no acl
  const allProjectsNames = flatten(siteList.flatMap(s => s.children).flatMap(z => z.children)).map(p => p.name);//Same as for zones
  const allPermissionAuthorities = ['USER', 'MANAGER'];

  //The names in headCells are used in the backend for sorting so they should not be changed
  //For example we want to sort by site label so the column name must be "siteLabel" which is read as "site.label" by the backend
  //Case is important too, "userLastName" is not correct but "userLastname" is
  const headCells = [
    {
      name: 'aclId',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'userId',
      numeric: true,
      label: 'Id',
      options: {
        setCellProps: () => {
          return {
            className: styles.manage_user__userId,
          };
        },
        sort: true,
        filter: false,
      },
    },
    {
      name: 'siteId',
      options: {
        display: 'excluded',
        filter: false,
        options: {
          sort: false,
        },
      },
    },
    {
      name: 'zoneId',
      options: {
        display: 'excluded',
        filter: false,
        options: {
          sort: false,
        },
      },
    },
    {
      name: 'projectId',
      options: {
        display: 'excluded',
        filter: false,
        options: {
          sort: false,
        },
      },
    },
    {
      name: 'userFirstname',
      numeric: false,
      label: 'FirstName',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'userLastname',
      numeric: false,
      label: 'LastName',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'resourcePath',
      numeric: false,
      label: 'Path (Site/Zone/Project)',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'siteLabel',
      options: {
        display: 'excluded',
        sort: false,
        filterType: 'dropdown',
        filterOptions: {
          names: allSitesLabels,
        },
      },
    },
    {
      name: 'zoneName',
      options: {
        display: 'excluded',
        sort: false,
        filterType: 'dropdown', //Should we also display site acl here ?
        filterOptions: {
          names: allZonesNames,
        },
      },
    },
    {
      name: 'projectName',
      options: {
        display: 'excluded',
        sort: false,
        filterType: 'dropdown', //Should we also display site and zone acl here ?
        filterOptions: {
          names: allProjectsNames,
        },
      },
    },
    {
      name: 'permissionAuthority',
      numeric: false,
      label: 'Status', //FIXME should we rename this column ? status -> permission / authority 
      options: {
        sort: true,
        filterType: 'dropdown', 
        filterOptions: {
          names: allPermissionAuthorities,
        },
        customBodyRender: (value, data) => {
          const row: Row = data.rowData;
          const canEdit = canEditStatus(row);
          const isPending = pendingUpdates.includes(row[COL_ACLID]);
          return (
            <>
              <FormControl>
                <NativeSelect
                  value={value}
                  disabled={!canEdit || isPending}
                  onChange={event => handlePermissionChange(data, row, event.target.value)}
                  classes={{
                    root: value === MANAGER ? styles.manage_user__darkBlueOption : styles.manage_user__darkSkyBlueOption,
                    select: styles.manage_user__statusLabel,
                    disabled: styles.manage_user__inputDisabled,
                  }}
                  className='user-authority-select'>
                  <option value={MANAGER}>MANAGER</option>
                  <option value={USER}>USER</option>
                </NativeSelect>
              </FormControl>
              {isPending && <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: 'relative', top: 4 }}/>}
            </>
          );
        },
      },
    },
    {
      name: 'expirationDate',
      numeric: false,
      label: 'EndOfAccess',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, data) => {
          const row: Row = data.rowData;
          const canEdit = canEditExpirationDate(row);
          const isPending = pendingUpdates.includes(row[COL_ACLID]);
          const months = process.env.REACT_APP_MAX_RIGHTS_EXPIRATION_TIME_MONTH ? +process.env.REACT_APP_MAX_RIGHTS_EXPIRATION_TIME_MONTH : 6;
          return (
            <div className={styles.manage_user__dateContainer}>
              <DatePicker
                disabled={!canEdit || isPending}
                slots={{
                  openPickerIcon: DatePickerIcon,
                  leftArrowIcon: KeyBoardArrowLeft,
                  rightArrowIcon: KeyBoardArrowRight,
                }}
                onChange={date => handleDateChange(data, row, date)}
                value={DateTime.fromISO(value)}
                maxDate={DateTime.local().plus({ months: months })}
                minDate={DateTime.local().plus({ days: 1 })}
                format='yyyy/MM/dd'
                timezone='Europe/Paris'
                className='user-authority-date-picker'
                slotProps={{ textField: {
                  label: '',
                  classes: { root: styles.manage_user__datePickerInputField },
                  sx: {
                    '& .MuiInputBase-input.Mui-disabled': {
                      class: styles.manage_user__datePickerInputFieldDisabled,
                    },
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  },
                  InputLabelProps: { shrink: true, classes: { root: styles.manage_user__datePickerInputFieldLabel } },
                } }}/>
              {isPending && <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: 'relative', top: 4 }}/>}
              <div className={styles.manage_user__warningIconContainer}>
                {expiresInNbMonths(row[COL_EXPIRATIONDATE], 1) && !isExpiredDate(row[COL_EXPIRATIONDATE]) && (
                  <Tooltip title='Permission will expire in less than 1 month'>
                    <IconWarning className={styles.manage_user__warningColor}/>
                  </Tooltip>
                )}

                {isExpiredDate(row[COL_EXPIRATIONDATE]) && (
                  <Tooltip title='Permission is expired'>
                    <IconWarning className={styles.manage_user__errorColor}/>
                  </Tooltip>
                )}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'isExpired', //Only for filter
      label: ' ',
      options: {
        filterType: 'checkbox',
        filterOptions: {
          names: ['Access expired'],
        },
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'willExpireWithin30Days', //Only for filter
      label: ' ',
      options: {
        filterType: 'checkbox',
        filterOptions: {
          names: ['Access will expire within 30 days'],
        },
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'machineTemplateId',
      numeric: false,
      label: 'Machine category',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: number, data) => {
          const row: Row = data.rowData;
          const isPending = pendingUpdates.includes(row[COL_ACLID]);
          const canEdit = canEditDefaultMachineTemplate(row);
          return (
            <div className={styles.manage_user__categoryContainer}>
              <FormControl>
                <NativeSelect
                  value={value}
                  disabled={!canEdit || isPending}
                  classes={{
                    root: getMachineTemplateOptionColor(value, getSiteAvailableMachineTemplatesFromState(row[COL_SITEID])),
                    select: styles.manage_user__statusLabel,
                    disabled: styles.manage_user__inputDisabled,
                  }}
                  className='user-authority-select'
                  onChange={event => handleMachineTemplateChange(data, row, event.target.value)}>
                  {getSiteAvailableMachineTemplatesFromState(row[COL_SITEID])?.map(machineTemplate => (
                    <option value={machineTemplate.id} key={machineTemplate.id}>
                      {machineTemplate.name.toUpperCase()}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              {isPending && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}/>
              )}
              <div className={styles.manage_user__warningCategoryIconContainer}>
                {!isTemplateValid(value, getSiteAvailableMachineTemplatesFromState(row[COL_SITEID])) && (
                  <Tooltip title='Selected category does not exists for the corresponding site'>
                    <IconWarning className={styles.manage_user__errorColor}/>
                  </Tooltip>
                )}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'lastModifiedDate',
      numeric: false,
      label: 'LastUpdated',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, allData) => {
          return (
            <div className={styles.manage_user__dateContainer}>
              <Tooltip title={allData.rowData[COL_LASTMODIFYBY] + ' on ' + formatDateTimeFromMillis(value)}>
                <div className={styles.manage_user__datePickerInputFieldLabel}>{value && formatDateFromMillis(value)}</div>
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: 'lastModifiedBy',
      options: {
        display: 'excluded',
        filter: false, sort: false,
      },
    },
    {
      name: '',
      label: '', // "Actions" like Delete...
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false, // Cannot hide it !
        customBodyRender: (value, allData) => {
          const row = allData.rowData;
          const canDelete = canDeleteAcl(allData.rowData);
          return canDelete && <UserDeleteButton aclId={row[COL_ACLID]} deleteCallBack={refresh}/>;
        },
      },
    },
  ];

  const options = {
    print: false,
    filter: true,
    serverSide: true,
    count: count,
    page: page,
    searchText: tableState.searchText,
    elevation: 0,
    rowsPerPageOptions: [10, 25, 50, 100],
    draggableColumns: {
      enabled: true,
    },
    selectableRows: 'none',
    sortOrder: tableState.sortOrder,
    filterList: tableState.filterList,
    rowsPerPage: tableState.rowsPerPage,
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={forceRefresh}>
            <Tooltip title='Refresh' placement='top'>
              <RefreshIcon/>
            </Tooltip>
          </IconButton>
          {isManager && (
            <IconButton color='primary'>
              <Tooltip title='Add user' placement='top'>
                <AddUsers callBack={refresh} withMenuStyle={false}>
                  <AddUsersIcon/>
                </AddUsers>
              </Tooltip>
            </IconButton>
          )}
        </>
      );
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'search':
          // Search option is handled differently to have a debounce and avoid calling backend multiple times
          setSearchText(newTableState.searchText);
          setTableState(newTableState);
          break;
        case 'changePage':
        case 'changeRowsPerPage':
        case 'filterChange':
        case 'sort':
        case 'resetFilters':
          changePage(newTableState.page, newTableState.rowsPerPage, newTableState.sortOrder, newTableState.searchText, newTableState.filterList);
          setTableState(newTableState);
          break;
        default:
          break;
      }
    },
  };

  return (
    <div className={styles.manage_user__topContainer}>
      {!isReady && (
        <div className={styles.manage_user__loadingSpinner}>
          <Loader/>
        </div>
      )}
      {
        isReady && (
          <>
            <div className={styles.manage_user__tableContainer}>
              <MUIDataTable
                title={
                  <>
                    <span className={styles.manage_user__title}>Users rights</span>
                    {pendingUpdates.length > 0 && (
                      <>
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: 'relative', top: 4 }}/>
                      </>
                    )}
                  </>
                }
                data={localData}
                columns={headCells}
                options={options}/>
            </div>
          </>
        )
      }
    </div>
  );
};

export default connector(ManageUsers);
