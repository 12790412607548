import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store';
import { clearProjectFilesAction, getProjectFilesAction } from 'store/actions/file-download-actions';
import { RootState } from 'store/reducers';
import './project-download.scss';
import BrowserDownloadTab from './browser-download-tab/browser-download-tab';
import CommandLineDownloadTab from './command-line-download-tab/command-line-download-tab';
import TablePagination from '@mui/material/TablePagination';

const mapState = (state: RootState) => ({
  projectFiles: state.fileDownloadReducer.projectFiles,
  continuationToken: state.fileDownloadReducer.continuationToken,
  pageSize: state.fileDownloadReducer.pageSize,
  lastItemIndex : state.fileDownloadReducer.lastItemIndex,
});

const mapDispatch = (dispatch) => bindActionCreators(
  {
    clearProjectFilesAction,
  },
  dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  projectId?: number,
  siteId?: number,
};

type Props = ReduxProps & OwnProps;

const ProjectDownload = ({
                           projectId,
                           siteId = 0,
                           projectFiles,
                           continuationToken,
                            lastItemIndex,
                            pageSize,
                           clearProjectFilesAction,
}: Props) => {

  const [sId, setsId] = useState<number>(siteId || 0);
  const [pId, setpId] = useState<number | undefined>(projectId);
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const [previousTokens, setPreviousTokens] = useState<{token: string | null; lastItemIndex : number}[]>([]);



  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage =  parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage)
    setPage(0);
    setPreviousTokens([]);
    dispatch(getProjectFilesAction(sId, pId, null, 0, newRowsPerPage));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > page){
      if (continuationToken){
        setPreviousTokens((prevTokens)=>[...prevTokens, {token: continuationToken, lastItemIndex: lastItemIndex}]);
        dispatch(getProjectFilesAction(sId, pId, continuationToken, lastItemIndex, rowsPerPage));
      }
    }
    else {
      if (previousTokens.length > 0) {
        const updatedTokens = previousTokens.slice(0, previousTokens.length - 1);
        const previousToken = updatedTokens[updatedTokens.length - 1] || { token: null, lastItemIndex: 0 };
        setPreviousTokens(updatedTokens);
        dispatch(getProjectFilesAction(sId, pId, previousToken.token, previousToken.lastItemIndex, rowsPerPage));
      }
    }
    setPage(newPage);
  };

  useEffect(() => {
    setpId(projectId);
    setsId(siteId);
    dispatch(getProjectFilesAction(sId, pId, null, 0, rowsPerPage));
    return () => {
      clearProjectFilesAction();
    };
  }, [projectId, siteId, sId, pId, getProjectFilesAction, clearProjectFilesAction]);

  return (
    <>
        <div className='project-download'>
      <div className='title'>DOWNLOAD</div>
      <div className='container'>
        <div className='description'>
          <div>To make files available for download, please move them to the /export using one of your sessions.</div>
          <div>Files in the /export directory will be removed automatically after a few days.</div>
          <div>Once moved/copied to this directory, the file will appear below and will be available for download or be shared through a temporary link to an email of your choosing</div>
          <br/>
          <div><strong>BROWSER</strong> download is the right choice if you want to download a <strong>specific</strong> file</div>
          <div><strong>COMMAND LINE</strong> download is best suited if you want to download <strong>all</strong> of the files or for <strong>large</strong> files that take hours to download.</div>
        </div>

        <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
          <Tab label='BROWSER' />
          <Tab label='COMMAND LINE' />
        </Tabs>
        {tabIndex === 0 && (
          <>
            <BrowserDownloadTab projectId={pId} siteId={sId} projectFiles={projectFiles}/>
            <TablePagination
              component='div'
              count={-1}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage='Files per page'
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={
                {disabled: previousTokens.length === 0}
              }
              nextIconButtonProps={
                {disabled: !continuationToken || false}
              }
            />
          </>
        )}
        {tabIndex === 1 && (
          <>
             <CommandLineDownloadTab projectId={pId} siteId={sId}/>
          </>
        )}
      </div>
    </div>


</>
  );
};

export default connector(ProjectDownload);