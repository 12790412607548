import { 
  RECEIVE_PROJECT_FILES, 
  CLEAR_PROJECT_FILES, 
  FileDownloadActionType
} from 'store/actions/file-download-actions';
import { FileDTO } from 'types';

export type DownloadState = {
  projectFiles: FileDTO[] | undefined,
  continuationToken : string | null,
  pageSize : number,
  lastItemIndex : number,
};

const initialState: DownloadState = {
  projectFiles: undefined,
  continuationToken : null,
  pageSize: 100,
  lastItemIndex : 0,
};

const fileDownloadReducer = (state = initialState, action: FileDownloadActionType): DownloadState => {
  switch (action.type) {
    case RECEIVE_PROJECT_FILES:
      return {
        ...state,
        projectFiles: action.projectFiles.content,
        continuationToken : action.projectFiles.continuationToken,
        pageSize: action.projectFiles.pageSize,
        lastItemIndex: action.projectFiles.lastItemIndex,
      };

    case CLEAR_PROJECT_FILES:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default fileDownloadReducer;